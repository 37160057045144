import React from "react";
import styles from "./CatalogButton.module.css";

function CatalogButton({
  id,
  onClick,
  title,
  desc = "Fai una scelta",
  disabled,
  selected,
}) {
  return (
    <button
      id={id}
      type="button"
      className={`${styles["btn-cat"]} ${selected && styles["selected-btn"]}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles["btn-spans"]}>
        <span
          className={`${styles["btn-title"]}`}
          style={{color: selected && 'white'}}
        >
          {title}
        </span>
        <span className={styles["btn-desc"]}>{desc}</span>
      </div>

      {!selected ? (
        <svg
          className={styles["chevron"]}
          style={{display: disabled && 'none'}}
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icons / Navigation / Chevron / Right">
            <path
              id="Shape"
              d="M16 29.6958L14.6667 28.5455L22.8333 20.4939L14.6667 12.4422L16 11.292L25.3333 20.4939L16 29.6958Z"
              fill="#0066CC"
            />
          </g>
        </svg>
      ) : (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icons / General / Pencil">
            <path
              id="Shape"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.5334 3.46685C25.5916 3.46685 26.6036 3.90056 27.3334 4.66685C28.1072 5.41988 28.5437 6.45377 28.5437 7.53352C28.5437 8.61327 28.1072 9.64715 27.3334 10.4002L11.7334 25.8669L4.80005 27.2002L6.13338 20.2669L21.6 4.66685C22.3795 3.89299 23.435 3.46121 24.5334 3.46685ZM20.6667 7.46685L19.7334 8.40018L23.6 12.2669L24.5334 11.3335L20.6667 7.46685ZM18.8 9.46685L20.2667 10.8002L9.06672 22.0002L7.60005 20.5335L18.8 9.46685ZM6.80005 24.0002C7.1174 23.9887 7.41879 24.1394 7.60005 24.4002C7.8608 24.5814 8.01149 24.8828 8.00005 25.2002L10 24.8002L7.20005 22.0002L6.80005 24.0002ZM11.4667 24.4002L10 22.9335L21.2 11.7335L22.5334 13.2002L11.4667 24.4002ZM25.4667 10.4002L26.4 9.46685C27.4329 8.38486 27.4329 6.68217 26.4 5.60018C25.9077 5.09775 25.2368 4.8102 24.5334 4.80018C23.7894 4.80356 23.0745 5.08953 22.5334 5.60018L21.6 6.53352L25.4667 10.4002Z"
              fill="#FCFCFF"
            />
          </g>
        </svg>
      )}
    </button>
  );
}

export default CatalogButton;
