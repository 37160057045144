import axios from 'axios';
const BASE_URL = '';

export const getMacrocategories = async (vista) => {
  try {
    const response = await axios.get(`${BASE_URL}/ext/v1/catalogo/nodi/?codiceLingua=it&vista=${vista}`);
    return response.data;
  } catch (error) {
    console.error('Errore nella richiesta API per ottenere le macrocategorie:', error);
  }
};

export const getChildrenById = async (macrocategoryId, vista) => {
  try {
    const response = await axios.get(`${BASE_URL}/ext/v1/catalogo/nodi/${macrocategoryId}?codiceLingua=it&vista=${vista}`);
    return response.data;
  } catch (error) {
    console.error('Errore nella richiesta API per ottenere la macrocategoria:', error);
    throw error;
  }
};

export const getAllProducts = async (vista) => {
  try {
    const response = await axios.get(`${BASE_URL}/ext/v1/catalogo/mappa/?codiceLingua=it&vista=${vista}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product catalog', error);
    return [];
  }
};

export const getProdottoById = async (idProdotto, vista) => {
  try {
    const response = await axios.get(`${BASE_URL}/ext/v1/catalogo/prodotto/${idProdotto}?codiceLingua=it&vista=${vista}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching product by ID:', error);
    return [];
  }
};
