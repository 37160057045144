import { useEffect, useState } from "react";
import styles from "./ProductView.module.css";
import skeletonStyles from "../../pages/Catalog/SkeletonCatalog.module.css";

// Componente per visualizzare i riferimenti normativi
const Riferimenti = ({ data }) => {
  if (data?.riferimentiNormativi.length) {
    return (
      <ul style={{ overflow: "hidden" }}>
        {data.riferimentiNormativi.map((el) => {
          return (
            <li key={el.idRiferimento}>
              <a href={el.link} target="_blank" rel="noopener noreferrer">
                {el.descrizione}
              </a>
            </li>
          );
        })}
      </ul>
    );
  } else return data?.riferimentiNormativiFallback;
};

// Funzione per creare l'elenco dei documenti
const createDocsList = (data) => {
  return data.map((el) => {
    return (
      <li key={el.idDocumento}>
        <a href={el.link} target="_blank" rel="noopener noreferrer">
          {el.descrizione}
        </a>
      </li>
    );
  });
};

// Componente per visualizzare i documenti
const Documenti = ({ data, type }) => {
  if (data?.documenti.length) {
    let inputDocs = [];
    let outputDocs = [];
    data.documenti.forEach((el) => {
      if (el.input) {
        inputDocs.push(el);
      } else {
        outputDocs.push(el);
      }
    });
    if (type === "input" && inputDocs.length === 0)
      return data?.documentiInputFallback;
    if (type === "output" && outputDocs.length === 0)
      return data?.documentiOutputFallback;
    return (
      <ul style={{ overflow: "hidden" }}>
        {type === "input" && createDocsList(inputDocs)}
        {type === "output" && createDocsList(outputDocs)}
      </ul>
    );
  }
  if (type === "input") return data?.documentiInputFallback;
  if (type === "output") return data?.documentiOutputFallback;
};

// Componente Skeleton per il caricamento
const Skeleton = () => {
  return (
    <div className={skeletonStyles["skeleton-container"]}>
      <div className={skeletonStyles["skeleton-box2"]} />
    </div>
  );
};

// Componente per i bottoni dei processi (Iter Digitale, Prenota Ora, Paga Online)
const BottoniProcessi = ({ iterDigitale, prenota, paga, onButtonClick }) => {
  // Funzione per gestire il click sui bottoni
  const handleButtonClick = (processi, title) => {
    if (processi.length === 1) {
      window.open(processi[0].link, "_blank");
    } else {
      onButtonClick(processi, title);
    }
  };

  return (
    <div className={styles["stile-Bottoni"]}>
      <span>
        {iterDigitale.length > 1 && (
          <button
            onClick={() => handleButtonClick(iterDigitale, "Iter Digitale")}
            type="button"
            className="btn btn-primary btn-xs"
          >
            Avvia Iter Digitale
          </button>
        )}

        {prenota.length > 1 && (
          <button
            onClick={() => handleButtonClick(prenota, "Prenota Ora")}
            type="button"
            className="btn btn-primary btn-xs"
          >
            Prenota Ora
          </button>
        )}

        {paga.length > 0 && (
          <button
            onClick={() => handleButtonClick(paga, "Paga Online")}
            type="button"
            className="btn btn-primary btn-xs"
          >
            Paga Online
          </button>
        )}
      </span>
    </div>
  );
};

// Componente per il popup che mostra i dettagli dei processi
const Popup = ({ title, processes, onClose }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return (
    <div className={styles.popupOverlay}>
      <button
        className={styles.popupBackdrop}
        onClick={onClose}
        onKeyDown={onClose}
      />
        <div
          className={styles.popupContent}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10%",
            }}
          >
            <h2 style={{ flexGrow: 1, margin: 0 }}>{title}</h2>
            <button
              className={styles.closeButton}
              onClick={onClose}
              onKeyDown={onClose}
            >
              <img
                src={require("../../assets/x.png")}
                width={15}
                height={15}
                alt="android ico"
                className={styles.closeButton}
              />
            </button>
          </span>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            {processes
              .sort((a, b) => a.ordinamento - b.ordinamento)
              .map((process) => (
                <span key={process.id}>
                  <div className={styles.processItem}>
                    <div className={styles.processInfo}>
                      <div>
                        <h5>{process.denominazione}</h5>
                      </div>
                      <div>{process.descrizione}</div>
                    </div>
                    <button
                      className="btn btn-primary btn-xs"
                      style={{ margin: 0 }}
                      onClick={() => window.open(process.link, "_blank")}
                    >
                      Avvia
                    </button>
                  </div>
                  <hr />
                </span>
              ))}
          </div>
        </div>
    </div>
  );
};

// Componente principale ProductView
function ProductView({ data, onClick, isLoading }) {
  const [iterDigitale, setIterDigitale] = useState([]);
  const [prenota, setPrenota] = useState([]);
  const [paga, setPaga] = useState([]);
  const [popupData, setPopupData] = useState(null);

  // Effetto per inizializzare i processi a partire dai dati forniti
  useEffect(() => {
    if (data) {
      const processi = data.processi;
      const currIter = [];
      const currPrenota = [];
      const currPaga = [];
      if (processi.length) {
        processi.forEach((p) => {
          if (p.tipologia === 0) currIter.push(p);
          if (p.tipologia === 1) currPrenota.push(p);
          if (p.tipologia === 2) currPaga.push(p);
        });
      }
      setIterDigitale(currIter);
      setPrenota(currPrenota);
      setPaga(currPaga);
    }
  }, [data]);

  const isAtListOneButton =
    iterDigitale.length > 0 || prenota.length > 0 || paga.length > 0;

  const handleButtonClick = (processi, title) => {
    if (processi.length === 1) {
      window.open(processi[0].link, "_blank");
    } else {
      setPopupData({ title, processes: processi });
    }
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const hasButtons =
    iterDigitale.length > 0 || prenota.length > 0 || paga.length > 0;

  return (
    <div className={styles["container-prod"]}>
      <span className={styles["span-prod"]}>
        {isLoading ? <Skeleton /> : data?.titolo}
      </span>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Descrizione</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : data?.descrizione}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Ulteriori dettagli</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : data?.ulterioriDettagli}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Normativa</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : <Riferimenti data={data} />}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Documenti per la richiesta</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : <Documenti data={data} type="input" />}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Documenti rilasciati</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : <Documenti data={data} type="output" />}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Competenza territoriale</span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : data?.competenzaTerritoriale}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>
          Codice tariffa nazionale (senza esenzioni)
        </span>
        <span className={styles["span-desc"]}>
          {isLoading ? <Skeleton /> : data?.codiciTariffaFallback}
        </span>
      </div>
      <div className={styles["data-wrapper"]}>
        <span className={styles["span-prod"]}>Importo</span>
        <span className={styles["span-desc"]}>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {data?.importoDiritti && (
                <div>Diritti: {data.importoDiritti} </div>
              )}
              {data?.importoBolli && <div>Bolli: {data.importoBolli} </div>}
              {data?.importoFallback &&
                !data?.importoDiritti &&
                !data?.importoBolli && <div> {data.importoFallback} </div>}
            </>
          )}
        </span>
      </div>
      <hr />
      <div
        className={styles["btn-container"]}
        style={{
          display: "flex",
          justifyContent: isAtListOneButton ? "space-between" : "center",
        }}
      >
        <button
          onClick={onClick}
          type="button"
          className="btn btn-primary btn-xs"
        >
          Torna indietro
        </button>

        {hasButtons && (
          <BottoniProcessi
            iterDigitale={iterDigitale}
            prenota={prenota}
            paga={paga}
            onButtonClick={handleButtonClick}
          />
        )}
      </div>
      {popupData && (
        <Popup
          title={popupData.title}
          processes={popupData.processes}
          onClose={closePopup}
        />
      )}
    </div>
  );
}
export default ProductView;
