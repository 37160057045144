import React from "react";
import styles from "./SelectorCategory.module.css";

function SelectorCategory({ title, onClick, selected, index }) {
  return (
    <button
      className={`${styles["macro-btn"]} ${
        selected && styles["macro-btn-sel"]
      }`}
      onClick={onClick}
      value={index}
    >
      {title}
    </button>
  );
}

export default SelectorCategory;
