// ToggleSwitch.js
import React, { useState } from 'react';
import styles from './TipoUtenteToggle.module.css';
import { PRIVATO_CITTADINO, OPERATORE_PROFESSIONALE } from '../../util/viste-enums';

const ToggleSwitch = ({ selected, handleToggle, setVistaChanged }) => {
  const handleToggleInternal = (vista) => {
    setVistaChanged((prev) => prev + 1);
    handleToggle(vista);
  };

  return (
    <div className={styles.toggleSwitch} role="tablist" aria-label="Tipo di Utente">
      <div
        className={`${styles.toggleOption} ${selected === PRIVATO_CITTADINO ? styles.selected : ''}`}
        role="tab"
        tabIndex={0}
        aria-selected={selected === PRIVATO_CITTADINO}
        onClick={() => handleToggleInternal(PRIVATO_CITTADINO)}
        onKeyDown={(e) => e.key === 'Enter' && handleToggleInternal(PRIVATO_CITTADINO)}
      >
        <svg className={styles['icon']} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 16c-4.41828 0-8-3.58172-8-8s3.58172-8 8-8 8 3.58172 8 8-3.58172 8-8 8zm0 2c-6.62742 0-12 5.37258-12 12h24c0-6.62742-5.37258-12-12-12z"
          />
        </svg>
        <span className={styles.text}>
          Privato
          <br />
          Cittadino
        </span>
      </div>
      <div
        className={`${styles.toggleOption} ${selected === OPERATORE_PROFESSIONALE ? styles.selected : ''}`}
        role="tab"
        tabIndex={0}
        aria-selected={selected === OPERATORE_PROFESSIONALE}
        onClick={() => handleToggleInternal(OPERATORE_PROFESSIONALE)}
        onKeyDown={(e) => e.key === 'Enter' && handleToggleInternal(OPERATORE_PROFESSIONALE)}
      >
        <span className={styles.text}>
          Operatore
          <br />
          Professionale
        </span>
        <svg
          className={styles['icon']}
          style={{ marginTop: '10px' }}
          width="48"
          height="48"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M22 21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25Z" />
          <path d="M17 2H7C4 2 3 3.79 3 6V22H21V6C21 3.79 20 2 17 2ZM10 17.25H7C6.59 17.25 6.25 16.91 6.25 16.5C6.25 16.09 6.59 15.75 7 15.75H10C10.41 15.75 10.75 16.09 10.75 16.5C10.75 16.91 10.41 17.25 10 17.25ZM10 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H10C10.41 11.25 10.75 11.59 10.75 12C10.75 12.41 10.41 12.75 10 12.75ZM10 8.25H7C6.59 8.25 6.25 7.91 6.25 7.5C6.25 7.09 6.59 6.75 7 6.75H10C10.41 6.75 10.75 7.09 10.75 7.5C10.75 7.91 10.41 8.25 10 8.25ZM17 17.25H14C13.59 17.25 13.25 16.91 13.25 16.5C13.25 16.09 13.59 15.75 14 15.75H17C17.41 15.75 17.75 16.09 17.75 16.5C17.75 16.91 17.41 17.25 17 17.25ZM17 12.75H14C13.59 12.75 13.25 12.41 13.25 12C13.25 11.59 13.59 11.25 14 11.25H17C17.41 11.25 17.75 11.59 17.75 12C17.75 12.41 17.41 12.75 17 12.75ZM17 8.25H14C13.59 8.25 13.25 7.91 13.25 7.5C13.25 7.09 13.59 6.75 14 6.75H17C17.41 6.75 17.75 7.09 17.75 7.5C17.75 7.91 17.41 8.25 17 8.25Z" />
        </svg>
      </div>
    </div>
  );
};

export default ToggleSwitch;
