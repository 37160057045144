import React, { useEffect, useState } from 'react';
import styles from './Catalog.module.css';
import CatalogButton from '../../components/CatalogButton/CatalogButton';
import TipoUtenteToggle from '../../components/TipoUtenteToggle/TipoUtenteToggle';
import SelectMacroButton from '../../components/SelectMacroButton/SelectMacroButton';
import SelectorCategory from '../../components/SelectorCategory/SelectorCategory';
import ProductView from '../../components/ProductView/ProductView';
import skeletonStyles from './SkeletonCatalog.module.css';
import SelectorProduct from '../../components/SelectorProduct/SelectorProduct';
import { getChildrenById, getProdottoById } from '../../api/service';

const Skeleton = () => {
  return (
    <div className={skeletonStyles['skeleton-container']}>
      <div className={skeletonStyles['skeleton-box']} />
      <div className={skeletonStyles['skeleton-box']} />
      <div className={skeletonStyles['skeleton-box']} />
      <div className={skeletonStyles['skeleton-box']} />
    </div>
  );
};

function Catalog({ macroCategoriesList, handleToggleTipoUtente, selectedTipoUtente }) {
  const urlParams = new URLSearchParams(window.location.search);
  const queryParams = {
    macro: urlParams.get('macro'),
    cat: urlParams.get('cat'),
    sub: urlParams.get('sub'),
    prod: urlParams.get('prod')
  };

  const [labelCategoria, setLabelCategoria] = useState('Seleziona una macrocategoria');

  const [selectedMacrocategoryIndex, setSelectedMacrocategoryIndex] = useState();
  const [catList, setCatList] = useState();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState();
  const [subList, setSubList] = useState();

  const [selectedSubcategoryIndex, setSelectedSubcategoryIndex] = useState();
  const [prodList, setProdList] = useState();

  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [prodObj, setProdObj] = useState();

  const [currentSelected, setCurrentSelected] = useState({
    macro: '',
    cat: '',
    sub: '',
    prod: ''
  });

  const [disableBtns, setDisableBtns] = useState({
    cat: true,
    sub: true,
    prod: true
  });
  const [selectedBtns, setSelectedBtns] = useState({
    macro: false,
    cat: false,
    sub: false,
    prod: false
  });

  const [errorQuery, setErrorQuery] = useState({
    macro: false,
    cat: false,
    sub: false,
    prod: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [vistaChanged, setVistaChanged] = useState(0);

  useEffect(() => {
    if (vistaChanged != 0) {
      const setDefault = () => {
        setSelectedBtns({ macro: false, cat: false, sub: false, prod: false });
        setCurrentSelected({
          macro: '',
          cat: '',
          sub: '',
          prod: ''
        });

        setDisableBtns({
          cat: true,
          sub: true,
          prod: true
        });
      };
      urlParams.set('macro', 'all');

      urlParams.delete('name');
      urlParams.delete('cat');
      urlParams.delete('sub');
      urlParams.delete('prod');

      setDefault();

      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    }
  }, [vistaChanged]);

  useEffect(() => {
    const handleQuery = async (type, id) => {
      setIsLoading(true);
      try {
        let children = [];

        switch (type) {
          case 'macro': {
            const macro = macroCategoriesList.find((el) => el.idNodo === id);
            if (!macro) throw new Error('Macro not found');
            children = await getChildrenById(macro.idNodo, selectedTipoUtente);
            setCatList(children);
            setCurrentSelected((prev) => ({
              ...prev,
              macro: children[0]?.macrocategoria
            }));
            setLabelCategoria('Seleziona una categoria');
            setSelectedBtns((prev) => ({ ...prev, macro: true }));
            setDisableBtns((prev) => ({ ...prev, cat: false }));
            break;
          }
          case 'cat': {
            children = await getChildrenById(id, selectedTipoUtente);
            setSubList(children);
            setCurrentSelected((prev) => ({
              ...prev,
              cat: children[0]?.categoria,
              macro: children[0]?.macrocategoria
            }));
            setSelectedBtns((__) => ({
              macro: true,
              cat: true,
              sub: false,
              prod: false
            }));
            setDisableBtns((__) => ({
              macro: false,
              cat: false,
              sub: false,
              prod: true
            }));
            break;
          }
          case 'sub': {
            children = await getChildrenById(id, selectedTipoUtente);
            setProdList(children);
            setCurrentSelected((prev) => ({
              ...prev,
              sub: children[0]?.sottocategoria,
              macro: children[0]?.macrocategoria,
              cat: children[0]?.categoria
            }));
            setSelectedBtns((__) => ({
              macro: true,
              cat: true,
              sub: true,
              prod: false
            }));
            setDisableBtns((__) => ({
              macro: false,
              cat: false,
              sub: false,
              prod: false
            }));
            break;
          }
          case 'prod': {
            const product = await getProdottoById(id, selectedTipoUtente);
            setProdObj(product);
            setCurrentSelected({
              macro: product.macrocategoria,
              cat: product.categoria,
              sub: product.sottocategoria,
              prod: product.titolo
            });
            setSelectedBtns((__) => ({
              macro: true,
              cat: true,
              sub: true,
              prod: true
            }));
            setDisableBtns((__) => ({
              macro: false,
              cat: false,
              sub: false,
              prod: false
            }));
            break;
          }
          default:
            throw new Error('Unknown type');
        }
      } catch (error) {
        console.error(`Error handling query for ${type}:`, error);
        setErrorQuery((prev) => ({ ...prev, [type]: true }));
      } finally {
        setIsLoading(false);
      }
    };

    if (queryParams.macro && queryParams.macro !== 'all' && macroCategoriesList.length) {
      handleQuery('macro', queryParams.macro);
    }

    if (queryParams.cat && macroCategoriesList.length) {
      handleQuery('cat', queryParams.cat);
    }

    if (queryParams.sub && macroCategoriesList.length) {
      handleQuery('sub', queryParams.sub);
    }

    if (queryParams.prod && macroCategoriesList.length) {
      handleQuery('prod', queryParams.prod);
    }
  }, [macroCategoriesList, queryParams.cat, queryParams.macro, queryParams.prod, queryParams.sub]);

  async function handleMacroBtn(e, macro) {
    e.preventDefault();
    setSelectedMacrocategoryIndex(e.target.value);
    setSelectedBtns((prev) => ({ ...prev, macro: true }));
    setDisableBtns((prev) => ({
      ...prev,
      cat: false
    }));
    setCurrentSelected((prev) => ({
      ...prev,
      macro: null,
      cat: null,
      sub: null,
      prod: null
    }));

    urlParams.set('macro', macro.idNodo);
    urlParams.set('name', macro.titolo);

    urlParams.delete('cat');
    urlParams.delete('sub');
    urlParams.delete('prod');

    window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  async function handleCatBtn(e, cat) {
    e.preventDefault();
    setSelectedCategoryIndex(e.target.value);
    setSelectedBtns((prev) => ({ ...prev, cat: true }));
    setDisableBtns((prev) => ({
      ...prev,
      sub: false
    }));
    setCurrentSelected((prev) => ({
      ...prev,
      cat: null,
      sub: null,
      prod: null
    }));

    urlParams.set('cat', cat.idNodo);
    urlParams.delete('macro');
    urlParams.delete('sub');
    urlParams.delete('prod');
    urlParams.delete('name');

    window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  async function handleSubBtn(e, cat) {
    e.preventDefault();
    setSelectedSubcategoryIndex(e.target.value);
    setSelectedBtns((prev) => ({ ...prev, sub: true }));
    setDisableBtns((prev) => ({
      ...prev,
      prod: false
    }));
    setCurrentSelected((prev) => ({
      ...prev,
      sub: null,
      prod: null
    }));

    urlParams.set('sub', cat.idNodo);
    urlParams.delete('macro');
    urlParams.delete('cat');
    urlParams.delete('prod');
    urlParams.delete('name');

    window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  async function handleProdBtn(e, cat) {
    setSelectedProductIndex(e.target.value);
    setSelectedBtns((prev) => ({ ...prev, prod: true }));
    setCurrentSelected((prev) => ({
      ...prev,
      prod: null
    }));

    urlParams.set('prod', cat.idNodo);
    urlParams.delete('macro');
    urlParams.delete('cat');
    urlParams.delete('sub');
    urlParams.delete('name');

    window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
  }

  function unselectMacro() {
    if (selectedBtns.macro) {
      setCatList(null);
      setSubList(null);
      setProdObj(null);
      setSelectedBtns((prev) => ({
        ...prev,
        macro: false,
        cat: false,
        sub: false,
        prod: false
      }));
      setSelectedMacrocategoryIndex(null);
      setSelectedCategoryIndex(null);
      setSelectedSubcategoryIndex(null);
      setSelectedProductIndex(null);
      setDisableBtns((prev) => ({
        ...prev,
        cat: true,
        sub: true,
        prod: true
      }));
      urlParams.set('macro', 'all');
      urlParams.delete('cat');
      urlParams.delete('sub');
      urlParams.delete('name');
      urlParams.delete('prod');

      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    }
  }

  function unselectCat() {
    if (selectedBtns.cat) {
      setSelectedBtns((prev) => ({
        ...prev,
        cat: false,
        sub: false,
        prod: false
      }));
      setSelectedCategoryIndex(null);
      setSelectedSubcategoryIndex(null);
      setSelectedProductIndex(null);
      setDisableBtns((prev) => ({
        ...prev,
        sub: true,
        prod: true
      }));
      urlParams.set('macro', subList?.[0]?.macrocategoriaId);
      urlParams.delete('cat');
      urlParams.delete('sub');
      urlParams.delete('name');
      urlParams.delete('prod');

      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
      setSubList(null);
      setProdList(null);
      setProdObj(null);
    }
  }

  function unselectSub() {
    if (selectedBtns.sub) {
      setSelectedBtns((prev) => ({ ...prev, sub: false, prod: false }));
      setSelectedSubcategoryIndex(null);
      setSelectedProductIndex(null);
      setDisableBtns((prev) => ({
        ...prev,
        prod: true
      }));
      urlParams.set('cat', prodList?.[0]?.categoriaId);
      urlParams.delete('macro');
      urlParams.delete('sub');
      urlParams.delete('name');
      urlParams.delete('prod');
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
      setProdList(null);
    }
  }

  function unselectProd() {
    if (selectedBtns.prod) {
      setSelectedBtns((prev) => ({ ...prev, prod: false }));
      setSelectedProductIndex(null);
      urlParams.set('sub', prodObj?.sottocategoriaId);
      urlParams.delete('macro');
      urlParams.delete('cat');
      urlParams.delete('name');
      urlParams.delete('prod');
      setProdObj(null);
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    }
  }

  return (
    <div className={`container ${styles['height-full']}`}>
      <div className={styles['main-content']}>
        <div className={`${styles['inner-container']} col-4`}>
          <span className={styles['title-span']}>Fai la tua selezione</span>
          <div className={`${styles['btns-col']} ${styles['box']}`}>
            <CatalogButton
              id="btnMacro"
              title="Macrocategoria"
              onClick={unselectMacro}
              disabled={false}
              selected={selectedBtns.macro}
              desc={selectedBtns.macro ? currentSelected.macro : 'Fai una scelta'}
            />
            <CatalogButton
              id="btnCat"
              title="Categoria"
              onClick={unselectCat}
              disabled={disableBtns.cat}
              selected={selectedBtns.cat}
              desc={selectedBtns.cat ? currentSelected.cat : 'Fai una scelta'}
            />
            <CatalogButton
              id="btnSub"
              title="Sottocategoria"
              onClick={unselectSub}
              disabled={disableBtns.sub}
              selected={selectedBtns.sub}
              desc={selectedBtns.sub ? currentSelected.sub : 'Fai una scelta'}
            />
            <CatalogButton
              id="btnProd"
              title="Prodotto"
              onClick={unselectProd}
              disabled={disableBtns.prod}
              selected={selectedBtns.prod}
              desc={selectedBtns.prod ? currentSelected.prod : 'Fai una scelta'}
            />

            <hr />

            <TipoUtenteToggle selected={selectedTipoUtente} handleToggle={handleToggleTipoUtente} setVistaChanged={setVistaChanged} />
          </div>
        </div>
        <div className={`${styles['inner-container']} col-8`}>
          <span className={styles['title-span']}>{labelCategoria}</span>
          <div className={`${styles['container-col']} ${styles['box']}`}>
            {!selectedBtns.macro && (
              <>
                {isLoading && <Skeleton />}

                {!isLoading && (
                  <div className={styles['view']}>
                    <div className={styles['macro-view']}>
                      {macroCategoriesList.map((cat, i) => (
                        <SelectMacroButton
                          key={i}
                          index={i}
                          onClick={(e) => handleMacroBtn(e, cat)}
                          selected={selectedMacrocategoryIndex === i}
                          title={cat.titolo}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}

            {selectedBtns.macro && !selectedBtns.cat && (
              <>
                {isLoading && <Skeleton />}

                {!isLoading && (
                  <div className={styles['view']}>
                    <div className={styles['macro-view']}>
                      {catList
                        ?.sort((a, b) => a.titolo.localeCompare(b.titolo))
                        .map((cat, i) => (
                          <SelectMacroButton
                            key={i}
                            index={i}
                            onClick={(e) => handleCatBtn(e, cat)}
                            selected={selectedCategoryIndex === i}
                            title={cat.titolo}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedBtns.cat && !selectedBtns.sub && (
              <>
                {isLoading && <Skeleton />}

                {!isLoading && (
                  <div className={styles['view']}>
                    <div className={styles['macro-view']} style={{ flexDirection: 'column', gap: '15px' }}>
                      {subList
                        ?.sort((a, b) => a.titolo.localeCompare(b.titolo))
                        .map((cat, i) => (
                          <SelectorCategory
                            key={i}
                            index={i}
                            onClick={(e) => handleSubBtn(e, cat)}
                            selected={selectedSubcategoryIndex === i}
                            title={cat.titolo}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedBtns.sub && !selectedBtns.prod && (
              <>
                {isLoading && <Skeleton />}

                {!isLoading && (
                  <div className={styles['view']}>
                    <div className={styles['macro-view']} style={{ flexDirection: 'column', gap: '15px' }}>
                      {prodList
                        ?.sort((a, b) => a.titolo.localeCompare(b.titolo))
                        .map((cat, i) => (
                          <SelectorProduct
                            key={i}
                            index={i}
                            onClick={(e) => handleProdBtn(e, cat)}
                            selected={selectedProductIndex === i}
                            title={cat.titolo}
                            desc={cat.descrizione}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={styles['view']}>
              <div className={styles['macro-view']}>
                {!errorQuery.prod && selectedBtns.prod && <ProductView isLoading={isLoading} data={prodObj} onClick={unselectProd} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Catalog;
