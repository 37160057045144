import React from "react";

function HighlightedText({ text, highlight }) {
  if (!highlight) return <span>{text}</span>;

  const words = highlight.toLowerCase().split(' ').filter(term => term);
  const regex = new RegExp(`(${words.join('|')})`, "gi");

  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, i) =>
        words.includes(part.toLowerCase()) ? (
          <span key={i} style={{ fontWeight: 'bold' }}>
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </span>
  );
}

export default HighlightedText;
