import React, { useRef, useState } from "react";
import styles from "./InputCustom.module.css";
import { useClickOutside } from "@react-hookz/web";
import HighlightedText from "./HighlightedText";

function InputCustom({products}) {
  const containerRef = useRef();
  let currentPath = window.location.href.substring(0, window.location.href.indexOf('?'))
  
  // const navigate = useNavigate();
  const [text, setText] = useState("");
 
  useClickOutside(containerRef, () => {
    setText("");
  });

  const filterProductsByText = (data, query) => {
    if (!query) return data;
  
    const terms = query.toLowerCase().split(' ').filter(term => term);
  
    const filtered = data.filter(el => {
      const match = terms.every(term => el.text.toLowerCase().includes(term));
      return match;
    });
  
    return filtered;
  };

  const filtered = filterProductsByText(products, text)

  return (
    <div ref={containerRef}>
      <input
        onChange={(e) => setText(e.target.value)}
        className={styles["input-container"]}
        placeholder="Ricerca rapida..."
        value={text}
        style={{
          borderBottomLeftRadius: text && "0",
          borderBottomRightRadius: text && "0",
        }}
      />
      {filtered && text && (
        <ul className={styles["results-container"]}>
          {filtered.map((prod) => {
            return (
              <a key={prod.id} href={`${currentPath}${prod.link}`} className={styles["prod"]}>
                <li className={styles["item"]}>
                  <HighlightedText text={prod.text} highlight={text} />
                </li>
              </a>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default InputCustom;
