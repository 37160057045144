import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className={styles["ftr"]}>
          <div className={styles["ftr-ctn1"]}>
            <a
              href="https://trasparenza.mit.gov.it/"
              target="_blank"
              className={styles["ftr-a1"]}
              rel="noreferrer"
            >
              Vedi pagina Trasparenza
            </a>
            {/* <a href="todo" target="_blank" className={styles["ftr-a2"]}>
              Privacy Policy
            </a> */}
          </div>
          <div className={styles["ftr-ctn2"]}>
            <span className={styles["ftr-span"]}>Scarica iPatente:</span>
            <div className={styles["pointer img-app"]}>
              <a
                href="https://play.google.com/store/apps/details?id=it.iwikiphone.portaleautomobilista2&hl=it&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../../assets/Android.png")}
                  alt="android ico"
                />
              </a>
            </div>
            <div className={styles["pointer img-app"]}>
              <a
                href="https://apps.apple.com/it/app/ipatente/id417705371"
                target="_blank"
                rel="noreferrer"
              >
                <img src={require("../../assets/Ios.png")} alt="ios ico" />
              </a>
            </div>
            <div className={styles["pointer img-app"]}>
              <a
                href="https://appgallery.huawei.com/#/app/C102453875"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../../assets/AppGallery.png")}
                  alt="app gallery ico"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["copyright"]}>
        <span className={styles["ftr-copy"]}>
          Copyright ©2023 Il portale dell’automobilista. All rights reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
