import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Catalog from './pages/Catalog/Catalog';
import { useEffect, useState } from 'react';
import { getMacrocategories } from './api/service';
import { PRIVATO_CITTADINO, OPERATORE_PROFESSIONALE } from './util/viste-enums';

function App() {
  const urlParams = new URLSearchParams(window.location.search);

  const queryMacro = urlParams.get('macro');
  const queryCat = urlParams.get('cat');
  const querySub = urlParams.get('sub');
  const queryProd = urlParams.get('prod');

  const [macroCategoriesList, setMacroCategoriesList] = useState([]);
  const [selectedTipoUtente, setSelectedTipoUtente] = useState('PRIVATO_CITTADINO');

  const handleToggleTipoUtente = (vista) => {
    setSelectedTipoUtente(vista);
    fetchMacroCategories(vista);
    localStorage.setItem('vista-catalogo', vista);
  };

  const fetchMacroCategories = async (vista) => {
    try {
      const resp = await getMacrocategories(vista);
      setMacroCategoriesList(resp);
      return resp;
    } catch (error) {
      console.error('Error fetching macrocategories:', error);
    }
  };

  useEffect(() => {
    let vista = PRIVATO_CITTADINO;
    if (document.referrer.includes('ilportaledeltrasporto.it')) {
      vista = OPERATORE_PROFESSIONALE;
    } else {
      let vistaLS = localStorage.getItem('vista-catalogo');
      if (vistaLS) {
        vista = vistaLS;
      }
    }
    fetchMacroCategories(vista);
    setSelectedTipoUtente(vista);
    localStorage.setItem('vista-catalogo', vista);
  }, []);

  return (
    <>
      <Header />

      {queryMacro || queryCat || querySub || queryProd ? (
        <Catalog macroCategoriesList={macroCategoriesList} selectedTipoUtente={selectedTipoUtente} handleToggleTipoUtente={handleToggleTipoUtente} />
      ) : (
        <Home macroCategoriesList={macroCategoriesList} selectedTipoUtente={selectedTipoUtente} />
      )}

      <Footer />
    </>
  );
}

export default App;
