import React from "react";
import styles from './SelectorProduct.module.css'
import ReactShowMoreText from "react-show-more-text";

function SelectorProduct({ title, onClick, selected, index, desc }) {
  return (
    <div
      className={`${styles["macro-btn"]} ${
        selected && styles["macro-btn-sel"]
      }`}
    >
        <span className={styles["span-prod"]}>{title}</span>
        <ReactShowMoreText lines={2} more='Mostra Altro' less='Mostra Meno' className={styles["span-desc"]}>{desc}</ReactShowMoreText>
        <button type="button" className="btn btn-primary btn-xs" onClick={onClick} style={{maxWidth: '180px', marginTop: '8px'}}>Visualizza Dettaglio</button>
    </div>
  );
}

export default SelectorProduct;
