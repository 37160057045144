import React from "react";
import styles from "./Header.module.css";

function Header() {
  // const {pathname} = useLocation();
  let currentPath = window.location.href.substring(0, window.location.href.indexOf('?'))


  return (
    <header>
      <div className={styles["blue-stripe"]}>
        <div className="container">
          <span className={styles["stripe-span"]}>
            Catalogo nazionale prodotti della motorizzazione
          </span>
        </div>
      </div>
      <div style={{ backgroundColor: "#0066cc" }}>
        <div className="container">
          <div className={styles["header-container"]}>
            {/* <Link to="/" style={{ marginTop: "10px" }}> */}
            <a href={`${currentPath}`} style={{ marginTop: "10px" }}>
              {true ? (
                <img
                  src="https://i.ibb.co/Wn7Xncd/Vettoriale-Logo-MIT-per-web-BIANCO.png"
                  style={{ height: "78px", width: "190px" }}
                  alt="ico white ministero delle infrastrutture e dei trasporti"
                />
              ) : (
                <img
                  src="https://i.ibb.co/jLJrKmy/Vettoriale-Logo-MIT-per-web-GRIGIO.png"
                  style={{ height: "78px", width: "190px" }}
                  alt="ico gray ministero delle infrastrutture e dei trasporti"
                />
              )}
            </a>
            {/* </Link> */}
            <div className={styles["a-header"]}>
              {/* <Link
                to="/"
                className={styles["link-header"]}
                style={{ marginRight: "32px",
                fontWeight: pathname === "/" && "bold" 
                }}
              > */}
              <a
                href={`${currentPath}`}
                className={styles["link-header"]}
                style={{ marginRight: "32px" }}
              >
                Home
              </a>
              {/* </Link> */}
              {/* <Link
                to="/?macro=all"
                className={styles["link-header"]}
                style={{fontWeight: pathname === "/catalog" && "bold"}}
              > */}
              <a href={`${currentPath}?macro=all`} className={styles["link-header"]}>
                Catalogo nazionale
              </a>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
